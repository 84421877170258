// src/App.js
import React from 'react';
import ChefForm from './pages/ChefForm';

function App() {
  return (
    <>
      <ChefForm/>
    </>
  );
}

export default App;
